import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Flex",
        "componentName": "Flex"
      }}>{`<Flex alignItems="center" justifyContent="space-around">
  <Box>Just flexing</Box>
  <Button>Click me!</Button>
</Flex>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Flex" mdxType="Props" />
    <h2 {...{
      "id": "aligncontent",
      "style": {
        "position": "relative"
      }
    }}>{`alignContent`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box height="300px" width="70px" clone>
  <Flex alignContent="center" flexWrap="wrap">
    <Box width="70px" height="70px" bgColor="primaryBase" />
    <Box width="70px" height="70px" bgColor="secondaryBase" />
    <Box width="70px" height="70px" bgColor="tertiaryBase" />
  </Flex>
</Box>
`}</code></pre>
    <p><strong parentName="p">{`Responsive { }`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box height="300px" width="70px" clone>
  <Flex
    alignContent={{
      xs: 'stretch',
      sm: 'center',
      md: 'space-between',
      lg: 'space-evenly',
      xl: 'space-around',
    }}
    flexWrap="wrap"
  >
    <Box width="70px" height="70px" bgColor="primaryBase" />
    <Box width="70px" height="70px" bgColor="secondaryBase" />
    <Box width="70px" height="70px" bgColor="tertiaryBase" />
  </Flex>
</Box>
`}</code></pre>
    <p><strong parentName="p">{`Responsive `}{`[ ]`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box height="300px" width="70px" clone>
  <Flex
    alignContent={[
      'stretch',
      'center',
      'space-between',
      'space-evenly',
      'space-around',
    ]}
    flexWrap="wrap"
  >
    <Box width="70px" height="70px" bgColor="primaryBase" />
    <Box width="70px" height="70px" bgColor="secondaryBase" />
    <Box width="70px" height="70px" bgColor="tertiaryBase" />
  </Flex>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "alignitems",
      "style": {
        "position": "relative"
      }
    }}>{`alignItems`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box height="300px" clone>
  <Flex alignItems="center">
    <Box width="70px" height="70px" bgColor="primaryBase" />
    <Box width="70px" height="70px" bgColor="secondaryBase" />
    <Box width="70px" height="70px" bgColor="tertiaryBase" />
  </Flex>
</Box>
`}</code></pre>
    <p><strong parentName="p">{`Responsive { }`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box height="300px" clone>
  <Flex
    alignItems={{
      xs: 'baseline',
      sm: 'center',
      md: 'flex-start',
      lg: 'flex-end',
      xl: 'center',
    }}
  >
    <Box width="70px" height="70px" bgColor="primaryBase" />
    <Box width="70px" height="70px" bgColor="secondaryBase" />
    <Box width="70px" height="70px" bgColor="tertiaryBase" />
  </Flex>
</Box>
`}</code></pre>
    <p><strong parentName="p">{`Responsive `}{`[ ]`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Box height="300px" clone>
  <Flex alignItems={['baseline', 'center', 'flex-start', 'flex-end', 'center']}>
    <Box width="70px" height="70px" bgColor="primaryBase" />
    <Box width="70px" height="70px" bgColor="secondaryBase" />
    <Box width="70px" height="70px" bgColor="tertiaryBase" />
  </Flex>
</Box>
`}</code></pre>
    <h2 {...{
      "id": "flexdirection",
      "style": {
        "position": "relative"
      }
    }}>{`flexDirection`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex flexDirection="column-reverse">
  <Box width="70px" height="70px" bgColor="primaryBase" />
  <Box width="70px" height="70px" bgColor="secondaryBase" />
  <Box width="70px" height="70px" bgColor="tertiaryBase" />
</Flex>
`}</code></pre>
    <p><strong parentName="p">{`Responsive { }`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex
  flexDirection={{
    xs: 'column-reverse',
    sm: 'row',
    md: 'row-reverse',
    lg: 'column',
    xl: 'column-reverse',
  }}
>
  <Box width="70px" height="70px" bgColor="primaryBase" />
  <Box width="70px" height="70px" bgColor="secondaryBase" />
  <Box width="70px" height="70px" bgColor="tertiaryBase" />
</Flex>
`}</code></pre>
    <p><strong parentName="p">{`Responsive `}{`[ ]`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex
  flexDirection={[
    'column-reverse',
    'row',
    'row-reverse',
    'column',
    'column-reverse',
  ]}
>
  <Box width="70px" height="70px" bgColor="primaryBase" />
  <Box width="70px" height="70px" bgColor="secondaryBase" />
  <Box width="70px" height="70px" bgColor="tertiaryBase" />
</Flex>
`}</code></pre>
    <h2 {...{
      "id": "flexwrap",
      "style": {
        "position": "relative"
      }
    }}>{`flexWrap`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex flexWrap="column-reverse">
  <Box width="70px" height="70px" bgColor="primaryBase" />
  <Box width="70px" height="70px" bgColor="secondaryBase" />
  <Box width="70px" height="70px" bgColor="tertiaryBase" />
</Flex>
`}</code></pre>
    <p><strong parentName="p">{`Responsive { }`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex
  flexWrap={{
    xs: 'column-reverse',
    sm: 'row',
    md: 'row-reverse',
    lg: 'column',
    xl: 'column-reverse',
  }}
>
  <Box width="70px" height="70px" bgColor="primaryBase" />
  <Box width="70px" height="70px" bgColor="secondaryBase" />
  <Box width="70px" height="70px" bgColor="tertiaryBase" />
</Flex>
`}</code></pre>
    <p><strong parentName="p">{`Responsive `}{`[ ]`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex
  flexWrap={[
    'column-reverse',
    'row',
    'row-reverse',
    'column',
    'column-reverse',
  ]}
>
  <Box width="70px" height="70px" bgColor="primaryBase" />
  <Box width="70px" height="70px" bgColor="secondaryBase" />
  <Box width="70px" height="70px" bgColor="tertiaryBase" />
</Flex>
`}</code></pre>
    <h2 {...{
      "id": "justifycontent",
      "style": {
        "position": "relative"
      }
    }}>{`justifyContent`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex justifyContent="space-between">
  <Box width="70px" height="70px" bgColor="primaryBase" />
  <Box width="70px" height="70px" bgColor="secondaryBase" />
  <Box width="70px" height="70px" bgColor="tertiaryBase" />
</Flex>
`}</code></pre>
    <p><strong parentName="p">{`Responsive { }`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex
  justifyContent={{
    xs: 'space-around',
    sm: 'space-evenly',
    md: 'flex-end',
    lg: 'center',
    xl: 'space-between',
  }}
>
  <Box width="70px" height="70px" bgColor="primaryBase" />
  <Box width="70px" height="70px" bgColor="secondaryBase" />
  <Box width="70px" height="70px" bgColor="tertiaryBase" />
</Flex>
`}</code></pre>
    <p><strong parentName="p">{`Responsive `}{`[ ]`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Flex
  justifyContent={[
    'space-around',
    'space-evenly',
    'flex-end',
    'center',
    'space-between',
  ]}
>
  <Box width="70px" height="70px" bgColor="primaryBase" />
  <Box width="70px" height="70px" bgColor="secondaryBase" />
  <Box width="70px" height="70px" bgColor="tertiaryBase" />
</Flex>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      